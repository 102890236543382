// Generated by Framer (b12dec4)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CTed0QVGj"];

const variantClassNames = {CTed0QVGj: "framer-v-mhy0b5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CTed0QVGj", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-xRq1z", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-mhy0b5", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CTed0QVGj"} ref={ref} style={{...style}}><SVG className={"framer-1p86225"} layout={"position"} layoutDependency={layoutDependency} layoutId={"HRneAor4M-shape"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 64 46\"><path d=\"M 62.663 7.041 C 64 12.065 64 22.546 64 22.546 C 64 22.546 64 33.026 62.663 38.05 C 61.926 40.821 59.758 43.004 57.004 43.745 C 52.013 45.091 32 45.091 32 45.091 C 32 45.091 11.987 45.091 6.996 43.745 C 4.242 43.004 2.073 40.821 1.337 38.05 C -0 33.026 -0 22.546 -0 22.546 C -0 22.546 -0 12.065 1.337 7.041 C 2.073 4.27 4.242 2.087 6.996 1.346 C 11.987 0 32 0 32 0 C 32 0 52.013 0 57.004 1.346 C 59.758 2.087 61.926 4.27 62.663 7.041 Z M 25.454 32.061 L 42.182 22.546 L 25.454 13.03 Z\" fill=\"rgba(0,0,0,1)\"></path></svg>"} svgContentId={3539766727} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-xRq1z [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xRq1z .framer-li8n6 { display: block; }", ".framer-xRq1z .framer-mhy0b5 { height: 46px; overflow: hidden; position: relative; width: 64px; }", ".framer-xRq1z .framer-1p86225 { flex: none; height: 46px; left: calc(50.00000000000002% - 64px / 2); position: absolute; top: calc(50.00000000000002% - 46px / 2); width: 64px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramernKTaM5T72: React.ComponentType<Props> = withCSS(Component, css, "framer-xRq1z") as typeof Component;
export default FramernKTaM5T72;

FramernKTaM5T72.displayName = "Social Buttons / Youtube";

FramernKTaM5T72.defaultProps = {height: 46, width: 64};

addFonts(FramernKTaM5T72, [])